import {acceptHMRUpdate, defineStore} from "pinia";
import {useDocs} from "~/store/docs";
import {useNav} from "~/store/app/nav";
import {useApp} from "~/store/app/app";

export const useSchedule = defineStore("schedule", {
    state: () => {
        const config = useRuntimeConfig();

        return {
            slots: null,
            calendar: null,
            schedule: [],
            apiBase: config.public.apiBase,
        };
    },
    getters: {
        getDates(state) {
            let dates = [];
            for (const clinicId in state.scheduleFilter) {
                for (const docId in state.scheduleFilter[clinicId]) {
                    for (const date in state.scheduleFilter[clinicId][docId]) {
                        dates.push(date);
                    }
                }
            }

            return [...new Set(dates)].sort((a, b) => new Date(a) - new Date(b)); // Удаление дубликатов и возврат уникальных дат
        },
        getSlotsByDate: (state) => (targetDate) => {
            let slotsByDate = {};

            for (const clinicId in state.scheduleFilter) {
                for (const docId in state.scheduleFilter[clinicId]) {
                    for (const date in state.scheduleFilter[clinicId][docId]) {
                        if (date === targetDate) {
                            if (!slotsByDate[date]) {
                                slotsByDate[date] = {};
                            }

                            if (!slotsByDate[date][clinicId]) {
                                slotsByDate[date][clinicId] = {};
                            }

                            slotsByDate[date][clinicId][docId] = state.scheduleFilter[clinicId][docId][date].slots;
                        }
                    }
                }
            }

            return slotsByDate;
        },
        scheduleFilter(state) {
            const result = JSON.parse(JSON.stringify(state.schedule));
            const appStore = useApp();

            for (const clinicId in result) {
                if (appStore.clinicCalendar !== null && appStore.clinicCalendar !== clinicId) {
                    delete result[clinicId];
                }
            }

            return result;
        },
    },
    actions: {
        async get(docId, clinicId, duration, listCategories, priceId) {
            const navStore = useNav();
            const docStore = useDocs();
            const docMisId = docStore.getDocMisId(docId, clinicId);
            const getDate = (str) => str.split(" ")[0];
            const getTime = (str) => {
                const [_, time] = str.split(" ");
                const [hour, minute] = time.split(":");
                return `${hour}:${minute}`;
            };
            let schedule = this.schedule;

            const requestOptions = {
                method: "GET",
                redirect: "follow",
            };

            let response;

            try {
                response = await fetch(
                    `${this.apiBase}/api/mis/slots?clinicId=${clinicId}&doctorId=${docMisId}&duration=${duration}`,
                    requestOptions
                );

                if (!response.ok) {
                    navStore.error = true;
                    return;
                }

                if (response.status === 204) {
                    return;
                }
            } catch (e) {
                console.log(e)
                return;
            }

            const slotsResponse = await response.json();

            if (!Array.isArray(slotsResponse)) {
                return;
            }

            for (let slot of slotsResponse) {
                const {
                    from: {_text: time},
                    priemID: {_text: slotCategoryId},
                } = slot;
                if (slotCategoryId === 0) console.log('Нулевая категория доктор/клиника: ', docId, clinicId);

                if (listCategories?.length > 0 && listCategories.indexOf(+slotCategoryId) === -1) {
                    continue;
                }

                const name = getTime(time);
                const rDate = getDate(time);

                schedule ??= {};
                schedule[clinicId] ??= {};
                schedule[clinicId][docMisId] ??= {};
                schedule[clinicId][docMisId][rDate] ??= {slots: []};

                const slotInDate = schedule[clinicId][docMisId][rDate].slots.find(
                    (item) => item.value === time
                );

                if (slotInDate) {
                    if (!slotInDate.listDocId.includes(docId)) {
                        slotInDate.listDocId.push(docId);
                    }
                } else {
                    schedule[clinicId][docMisId][rDate].slots.push({
                        name,
                        value: time,
                        category: slotCategoryId,
                        duration,
                        priceId,
                        listDocId: [docId],
                    });
                }
            }

            this.schedule = JSON.parse(JSON.stringify(schedule));

            return slotsResponse;
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSchedule, import.meta.hot));
}
