import { acceptHMRUpdate, defineStore } from "pinia";
import { useClinics } from "~/store/clinics";
import { useApi } from "~/store/app/api";
import { useSlots } from "~/store/slots";
import qs from "qs";
import { textDate } from "~/libs/date";
import { useDocs } from "~/store/docs";
import { usePatient } from "~/store/app/patient";
import { useEmail } from "~/store/email";
import { useUi } from "~/store/ui";

export const useHome = defineStore("home", {
    state: () => {
        const config = useRuntimeConfig();
        const { clinic } = useClinics();

        return {
            open: false,
            apiBase: config.public.apiBase,
            address: "",
            coords: [],
            listZones: [],
            zoom: 10,
            zone: null,
            clinic,
            addressComponents: {},
            save: false,
            nav: {
                currentStep: 1,
                maxStep: 4
            },
            select: {
                date: null,
                time: null
            },
            calendar: [],
            loader: false,
            docId: null,
            bookId: null,
            cachBookLink: null
        };
    },

    getters: {
        dates(state) {
            const result = [];
            if (this.calendar) {
                for (let date in this.calendar) {
                    result.push({ start: new Date(date), end: new Date(date) });
                }

                return result;
            } else {
                return [];
            }
        },
        timeList() {
            const result = [];

            if (this.select.date && this.calendar[textDate(this.select.date)]) {
                if (Object.values(this.calendar[textDate(this.select.date)]).length) {
                    for (let slot of this.calendar[textDate(this.select.date)]) {
                        result.push({ ...slot });
                    }
                }
            }

            function compare(a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }

            result.sort(compare);

            return result;
        },
        getZoneById: (state) => (zoneId) =>
            state.listZones.find((zone) => zone.id === zoneId),
        isCoordinateInsidePolygon: (state) => (coordinate, polygon) => {
            if (!(coordinate.length && polygon.length)) return false;
            // debugger;
            const x = coordinate[1];
            const y = coordinate[0];

            let inside = false;
            for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
                const xi = polygon[i].lon,
                    yi = polygon[i].lat;
                const xj = polygon[j].lon,
                    yj = polygon[j].lat;

                const intersect =
                    yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
                if (intersect) inside = !inside;
            }
            return inside;
        },
        zonePolygonByRegion: (state) => () => {
            // let listZones = state.listZones.filter((zone) => zone.attributes.regionId === regionId);
            let listZones = JSON.parse(JSON.stringify(state.listZones));

            if (listZones.length) {
                return listZones.map((zone) => {
                    return {
                        title: zone.attributes.title,
                        color: zone.attributes.color,
                        cost: zone.attributes.cost,
                        coords: [
                            [
                                ...zone.attributes.coordinates.map((coordinate) => [
                                    coordinate.lon,
                                    coordinate.lat
                                ])
                            ]
                        ]
                    };
                });
            }
            return [];
        },
        boundedCompile: (state) => (polygonList) => {
            if (!polygonList) {
                polygonList = state.zonePolygonByRegion();
            }
            ;

            if (polygonList) {
                polygonList = Object.values(polygonList).map(
                    (polygon) => polygon.coords[0]
                );

                let minLat = Infinity;
                let maxLat = -Infinity;
                let minLng = Infinity;
                let maxLng = -Infinity;

                for (let i = 0; i < polygonList.length; i++) {
                    const polygon = polygonList[i];
                    for (let j = 0; j < polygon.length; j++) {
                        // console.log(polygon[j]);
                        // const lat = Math.round(polygon[j][0] / 10) * 10;
                        // const lng = Math.round(polygon[j][1] / 10) * 10;

                        const lat = polygon[j][0];
                        const lng = polygon[j][1];

                        minLat = Math.min(minLat, lat);
                        maxLat = Math.max(maxLat, lat);
                        minLng = Math.min(minLng, lng);
                        maxLng = Math.max(maxLng, lng);
                    }
                }

                const bounds = [
                    [minLat, minLng],
                    [maxLat, maxLng]
                ];

                return bounds;
            }
            return false;
        },
        getRectangleVertices: (state) => (vertex1, vertex2) => {
            const lat1 = vertex1[0];
            const lng1 = vertex1[1];
            const lat2 = vertex2[0];
            const lng2 = vertex2[1];
            const minLat = Math.min(lat1, lat2);
            const maxLat = Math.max(lat1, lat2);
            const minLng = Math.min(lng1, lng2);
            const maxLng = Math.max(lng1, lng2);

            const rectangle = [
                [minLat, minLng],
                [minLat, maxLng],
                [maxLat, maxLng],
                [maxLat, minLng]
            ];

            return rectangle;
        },
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
            // return `${state.apiBase}${url}${
            //     state.cache ? "" : "&" + new Date().getTime()
            // }`;
        },
        canGoToStep: (state) => (step) => {
            switch (step) {
                case 1:
                    return true;
                case 2:
                    return state.zone !== null && Object.values(state.zone).length > 0;
                case 3:
                    return state.zone !== null && state.save;
                default:
                    return false;
            }
        }
    },
    actions: {
        async createHome() {
            const patientStore = usePatient();
            const clinicStore = useClinics();
            const docsStore = useDocs();
            if (!docsStore.listDocs.length) {
                await docsStore.fetchDocs();
            }
            const result = {};


            const day = this.calendar[textDate(this.select.date)];
            const timeTmp = day.find((item) => item.value === this.select.time);
            this.docId = timeTmp.listDocId[0];
            let doc = docsStore.getDocByMisId(this.docId);

            result.doctor = `${doc.attributes.fname} ${doc.attributes.lname}`;
            result.clinicId = useDocs().getDocByMisId(this.docId).attributes?.homeSlots?.data?.attributes?.clinicId;
            result.clinic = clinicStore.getClinicById(result.clinicId).attributes.title;


            const currentDate = new Date();
            const time = this.select.time;
            let endTime = new Date(time);
            if (currentDate > endTime) {
                return false;
            }
            result.time = time;
            result.time =
                result.time.split(" ")[0].split("-").reverse().join(".") +
                " " +
                result.time.split(" ")[1];
            endTime = new Date(endTime.getTime() + 120 * 60000);
            result.end = `${endTime.getDate()}.${endTime.getMonth() + 1}.${endTime.getFullYear()} ${endTime.getHours()}:${String(endTime.getMinutes()).padStart(2, "0")}:${String(endTime.getSeconds()).padStart(2, "0")}`;
            result.cost = this.zone.cost;


            result.target = ` || Выезд на дом || `;

            const name = patientStore.patient.name;
            const phone = patientStore.patient.phone;

            function reformatDate(date) {
                let dateParts = date.split(".");
                return dateParts.reverse().join("-");
            }

            const birthDay = reformatDate(patientStore.patient.bday);
            const comment =
                patientStore.patient.reason +
                " [" +
                result.target +
                "]" +
                " Адрес: " +
                this.address + " " +
                (patientStore.patient.front ? "пар. " + patientStore.patient.front + ", " : "") +
                (patientStore.patient.apartment ? "кв. " + patientStore.patient.apartment + ", " : "") +
                (patientStore.patient.floor ? "этаж " + patientStore.patient.floor + ", " : "") +
                (patientStore.patient.caddress
                    ? " | Комментарий: " + patientStore.patient.caddress + ""
                    : "");
            const duration = this.zone.time;
            const now = new Date();
            const cach = this.zone.cach;

            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            this.bookId = await fetch(
                `${this.apiBase}/api/mis/book?clinicId=${result.clinicId}&doctorId=${this.docId}&name=${name}&phone=${phone}&birthDay=${birthDay}T00:00:00.000Z&time=${time}&comment=${comment}&duration=${duration}&now=${now}&cach=${cach}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => result)
                .catch((error) => console.log("error", error));

            if (cach) {
                let cachLink = await fetch(
                    `${this.apiBase}/api/mis/cach?clinicId=${result.clinicId}&bookId=${this.bookId}&home=1`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => result)
                    .catch((error) => console.log("error", error));

                this.cachBookLink = cachLink["_text"].replace("&amp;", "&");
            }

            result.duration = duration;
            result.bookId = this.bookId;

            if (this.bookId) {
                useEmail().send("home", {
                    ...result,
                    fio: name,
                    birthDay: patientStore.patient.bday,
                    phone: phone,
                    address: this.address,
                    time: time,
                    end: endTime,
                    duration: duration,
                    cost: cach,
                    target: result.target,
                    comment: comment,
                    front: patientStore.patient.front,
                    apartment: patientStore.patient.apartment,
                    floor: patientStore.patient.floor,
                    caddress: patientStore.patient.caddress,

                });

                return result;
            }

            return false;
        },
        async cancelBook() {
            const clinicStore = useClinics();
            const clinicId = clinicStore.clinicId;

            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            await fetch(
                `${this.apiBase}/api/mis/cancelBook?clinicId=${clinicId}&bookId=${this.bookId}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => result)
                .catch((error) => console.log("error", error));
        },
        async createCalendar() {
            const uiStore = useUi();
            if (uiStore.debugMode) {
                console.group('createCalendar');
            }
            const docsStore = useDocs();

            if (this.zone?.docs?.data) {

                if (uiStore.debugMode) {
                    console.log('Врачи в зоне: ', this.zone.docs.data);
                }

                const listDocsMisId = this.zone.docs.data.map(
                    (doc) => {
                        const docTmp = docsStore.getDocById(doc.id);
                        const clinicId = docTmp?.attributes?.homeSlots?.data?.attributes?.clinicId;
                        const docId = docTmp?.attributes?.DocId?.find((item) => item.clinic.data.attributes.clinicId === clinicId)?.docId;
                        if (uiStore.debugMode) {
                            if (clinicId === undefined) {
                                console.error('Для врача не найден clinicId: ', docTmp.attributes.fname, docTmp?.attributes?.lname || '', docTmp?.attributes?.pname || '');
                            }

                            if (docId === undefined) {
                                console.error('Для врача не найден docId: ', docTmp.attributes.fname, docTmp?.attributes?.lname || '', docTmp?.attributes?.pname || '');
                            }
                        }
                        return docId;
                    }
                ).filter((docId) => docsStore.getDocByMisId(docId) !== null);

                this.calendar = await this.getSlots(listDocsMisId);
            }
            if (uiStore.debugMode) {
                console.groupEnd();
            }
        },
        async getSlots(listDocsMisId) {
            const uiStore = useUi();
            const time = this.zone?.time;

            if (time && listDocsMisId.length) {
                const slots = [];

                if (listDocsMisId && !Array.isArray(listDocsMisId)) {
                    listDocsMisId = [listDocsMisId];
                }

                if (uiStore.debugMode) {
                    console.group('getSlots');
                }

                for (let docId of listDocsMisId) {
                    if (docId !== null) {
                        const clinicId = useDocs().getDocByMisId(docId).attributes?.homeSlots?.data?.attributes?.clinicId;
                        const duration = time;
                        const doc = useDocs().getDocByMisId(docId);

                        if (uiStore.debugMode) {
                            console.log('Врач: ', doc.attributes.fname, doc.attributes.lname, doc.attributes.pname, 'клиника: ', clinicId, 'docId: ', docId, 'outsiteSlots: ', doc?.attributes?.outsiteSlots);
                        }
                        let categoryIds = [];
                        if (doc?.attributes?.outsiteSlots === false)
                            categoryIds = [1, 2];
                        else
                            categoryIds = [4];

                        if (clinicId) {
                            await fetch(
                                `${this.apiBase}/api/mis/slots?clinicId=${clinicId}&doctorId=${docId}&duration=${duration}`,
                                this.requestOptions
                            )
                                .then(async (response) => {
                                    if (response.status !== 200) return null;
                                    const responseJson = await response.json();
                                    if (uiStore.debugMode) {
                                        console.log('response: ', responseJson);
                                    }
                                    return responseJson;
                                })
                                .then((slotsResponse) => {
                                    if (slotsResponse && Array.isArray(slotsResponse)) {
                                        for (let slot of slotsResponse) {
                                            const {
                                                from: { _text: time },
                                                priemID: { _text: slotCategoryId }
                                            } = slot;
                                            if (!categoryIds.includes(+slotCategoryId)) {
                                                continue;
                                            }
                                            const name = this.getTime(time);
                                            const rDate = this.getDate(time);
                                            const slotInDate = slots[rDate]?.find(
                                                (item) => item.value === time
                                            );

                                            if (slotInDate) {
                                                if (!slotInDate.listDocId.includes(docId)) {
                                                    slotInDate.listDocId.push(docId);
                                                }
                                            } else {
                                                slots[rDate] = [
                                                    ...(slots[rDate] ?? []),
                                                    { name, value: time, listDocId: [docId] }
                                                ];
                                            }
                                        }
                                    }
                                })
                                .catch((error) => console.log("error", error));
                        } else {
                            console.log("ClinicId is empty for docId", docId);
                        }
                    }
                }
                if (uiStore.debugMode) {
                    console.groupEnd();
                }
                return slots;
            }
        },
        async fetchZonesAdd(region) {
            const apiStore = useApi();

            const populate = {
                fields: [
                    "color",
                    "title",
                    "cost",
                    "region"
                ],
                coordinates: "*",
                price: "*",
                docs: {
                    populate: {
                        fields: [
                            "fname",
                            "lname",
                            "pname",
                            "docId",
                            "consultation",
                            "clinics"
                        ],
                        homeSlots: {
                            populate: {
                                clinic: {
                                    fields: ["clinicId"]
                                }
                            }
                        }
                    }
                }
                //     populate: {
                //         zones: {
                //             populate: '*',
                //         },
                //         clinics: {
                //             fields: ['clinicId']
                //         }
                //     }
                // },
            };
            const filters = {
                region: {
                    "$contains": region
                }
            };

            const query = qs.stringify({ populate, filters, pagination: { pageSize: 1000 } }, { encode: true });
            const url = `/api/zones?${query}`;
            const listZones = await apiStore.fetchData(url);

            if (listZones?.length) {
                function parseCoordinateText(text) {
                    const lines = text.split("\n");
                    return lines.map((line) => {
                        const coordinates = line.split(",");
                        return {
                            lat: parseFloat(coordinates[0]),
                            lon: parseFloat(coordinates[1])
                        };
                    });
                }

                this.listZones = [...listZones].map((zone) => {
                    return {
                        ...zone,
                        attributes: {
                            ...zone.attributes,
                            coordinates: parseCoordinateText(zone.attributes.coordinates)
                        }
                    };
                });
            } else {
                console.log("List zones is empty");
            }
        },
        updateAddressComponents(components) {
            this.addressComponents = components;
        },
        openHome() {
            this.open = true;
            this.nav.currentStep = 1;
            this.select.date = null;
            this.select.time = null;
        },
        clearHomeData() {
            this.address = null;
            this.zone = null;
            this.coords = [];

            // Получаем доступ к store slots для очистки связанных данных
            const slotsStore = useSlots();
            slotsStore.target = null;
            slotsStore.mapApprove = null;
            slotsStore.patient.address = "";
            slotsStore.date = null;
            slotsStore.time = null;
            slotsStore.calendar = [];
        },
        nextStep() {
            if (this.nav.currentStep < this.nav.maxStep && this.canGoToStep(this.nav.currentStep + 1)) {
                this.nav.currentStep++;
            }
        },
        prevStep() {
            if (this.nav.currentStep > 1) {
                this.nav.currentStep--;
            }
        },
        setStep(step) {
            if (step >= 1 && step <= this.nav.maxStep) {
                this.nav.currentStep = step;
            }
        },
        getDate(str) {
            let arr = str.split(" ");
            return `${arr[0]}`;
        },
        getTime(str) {
            let arr = str.split(" ");
            let arrTime = arr[1].split(":");
            return `${arrTime[0]}:${arrTime[1]}`;
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useHome, import.meta.hot));
}
