import { acceptHMRUpdate, defineStore } from "pinia";
import { useEmail } from "~/store/email";
import { useClinics } from "~/store/clinics";
import { usePrograms } from "~/store/programs";

export const useOrder = defineStore("order", {
    state: () => {
        return {
            status: null,
            name: "",
            phone: "",
            comment: "",
            address: ""
        };
    },
    getters: {},
    actions: {
        async send() {
            try {
                const mailStore = useEmail();

                const payload = {
                    fio: this.name,
                    phone: this.phone,
                    comment: this.comment
                };

                await mailStore.send("order", payload);
            } catch (e) {
                console.error(e);
            }
        },
        async sendProgram() {
            try {
                const mailStore = useEmail();
                const programStore = usePrograms();
                const clinicStore = useClinics();

                const basketInfo = programStore.createBasketInfo();

                const payload = {
                    fio: this.name,
                    phone: this.phone,
                    comment: this.comment,
                    address: this.address,
                    clinic: clinicStore.getClinicById(programStore.clinicId).attributes.address,
                    age: basketInfo.age,
                    cost: basketInfo.cost,
                    program: basketInfo.program,
                    doctor: basketInfo.doctor,
                    online: programStore.leadOnline ? 'Ведущий врач' : 'Дежурный врач',
                };

                await mailStore.send(programStore.type, payload);
            } catch (e) {
                console.error(e);
            }
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useOrder, import.meta.hot));
}
