import { acceptHMRUpdate, defineStore } from "pinia";
import { useApp } from "~/store/app/app";
import { useDocs } from "~/store/docs";

export const usePatient = defineStore("patient", {
    state: () => ({
        patient: {
            age: null,
            name: null,
            phone: null,
            bday: null,
            reason: null,
            promo: null,
            comment: null,
            address: null,
            apartment: null,
            caddress: null,
            front: null,
            floor: null,
        },
        defaultPatient: {
            name: 'Фамилия Имя Отчество',
            phone: '+7 (921) 409 06 81',
            bday: '01.01.2019',
            reason: 'Тест. Удалить.',
            comment: 'Тестовая запись',
        },
        orvi: false,
        error: false,
    }),
    getters: {
        checkValidHome() {
            return this.checkValid.filter(field => field !== 'age');
        },
        checkValid() {
            const appStore = useApp();
            const docStore = useDocs();
            const errorFields = [];
            const fieldsToCheck = ['name', 'phone', 'bday'];
            const invalidField = field => !this.patient[field] || this.patient[field].length < 1;

            fieldsToCheck.forEach(field => {
                if (invalidField(field)) errorFields.push(field);
            });

            if (this.patient.phone && this.patient.phone?.length !== 18) errorFields.push('phone');

            if (this.patient.bday && this.patient.bday?.length !== 10) errorFields.push('bday');

            const checkAgeValidity = () => {
                const doc = appStore.hotDoc;

                if (doc && this.patient.bday) {
                    const today = new Date();
                    const [day, month, year] = this.patient.bday.split('.');
                    const birthDate = new Date(`${year}-${month}-${day}`);

                    // Calculate exact age including months and days
                    const ageDate = new Date(today - birthDate);
                    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
                    const isAdult = age <= 18;

                    // Check if age is within doctor's allowed range
                    const isInRange = doc.attributes.age_from !== null &&
                        doc.attributes.age_to !== null &&
                        doc.attributes.age_from !== undefined &&
                        doc.attributes.age_to !== undefined &&
                        age >= +doc.attributes.age_from &&
                        age <= +doc.attributes.age_to;

                    if (!isAdult) {
                        errorFields.push('age18');
                    }

                    if (!isInRange) {
                        errorFields.push('age');
                    }



                    return isInRange && isAdult;
                }
                return false;
            }

            if (this.patient.bday) {
                checkAgeValidity();
            }

            return errorFields;
        }
    },
    actions: {
        setDefault() {
            this.patient.age = 5;
            this.patient.name = 'Фамилия Имя Отчество';
            this.patient.phone = '+7 (921) 409 06 81';
            this.patient.bday = '01.01.2019';
            this.patient.reason = 'Тест. Удалить.';
        },
        setDefaultPatient() {
            this.patient.name = this.defaultPatient.name;
            this.patient.phone = this.defaultPatient.phone;
            this.patient.bday = this.defaultPatient.bday;
            this.patient.reason = this.defaultPatient.reason;
            this.patient.comment = this.defaultPatient.comment;
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePatient, import.meta.hot));
}
